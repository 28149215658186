
import $ from 'jquery';

var target = window.location.hash,
	target = target.replace('#', '');
history.replaceState(null, null, ' ');
$(window).on('load', function () {

	if (target) {
		var offsetTop = $("#" + target).offset().top - $('.c-header').height();

		$('html, body').animate({
			scrollTop: offsetTop
		}, 1000, 'swing', function () { });

		setTimeout(() => {
			$("#" + target).find('.js-accor').addClass('is-active').next().slideToggle();
		}, 1000);
	}
});

$('a[href*=\\#]:not([href=\\#])').click(function () {
	if (
		location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
		location.hostname == this.hostname
	) {
		var $target = $(this.hash);
		$target =
			($target.length && $target) || $('[name=' + this.hash.slice(1) + ']');
		if ($target.length) {
			var targetOffset = $target.offset().top - $('.c-header').height();
			$('html,body').animate({ scrollTop: targetOffset }, 1000);
			return false;
		}
	}
});
