import $ from 'jquery';

let btn = $('.c-header__contact');
let pagetop = $('.js-gotop');
let btngotop = $('.c-gotop__btn');
$(window).on('scroll', function () {
	if ($(this).scrollTop() > 50) {
		pagetop.addClass('is-show');
	} else {
		pagetop.removeClass('is-show');
	}

	if ($(window).width() <= 768) {
		let w_stop = $('.c-entry').offset().top - $(window).innerHeight();
		if ($(this).scrollTop() > w_stop) {
			pagetop.addClass('is-absolute');
			btn.addClass('is-hidden');
		} else {
			pagetop.removeClass('is-absolute');
			btn.removeClass('is-hidden');
		}
	} else {

		pagetop.removeClass('is-absolute');
		btn.removeClass('is-hidden');
	}
});

btngotop.on('click',function () {
	$('body, html').animate({ scrollTop: 0 }, 500);
});
