import $ from 'jquery';

$(function () {
	const btnAccor = $('.js-accor')

	btnAccor.on('click', function () {
		$(this).next().slideToggle()
		$(this).toggleClass('is-active')
	})
})
