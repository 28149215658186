import $ from 'jquery';

$(function () {
	const tab1 = $('.js-tab1')

	if (tab1.length) {
		tab1.each(function () {
			const _thisTab = $(this)

			_thisTab.find('[data-tab-btn]').on('click', function () {
				const data = $(this).data('tab-btn')

				_thisTab.find('[data-tab-btn]').removeClass('is-active')
				_thisTab.find('[data-tab-content]').removeClass('is-active')
				$(this).addClass('is-active')
				_thisTab.find(`[data-tab-content=${data}]`).addClass('is-active')
			})
		})
	}
})

