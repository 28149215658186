import $ from 'jquery';
import 'jquery-match-height';

$(function () {
	$('.js-mheight').matchHeight();
	$('.js-mtitle').matchHeight();
	$('.js-mtags').matchHeight();
	$('.js-mcontent').matchHeight();
	$('.js-mtext').matchHeight();
})
