import $ from 'jquery';

$(function () {
	const scroll1 = $('.js-scroll1')

	if (scroll1.length) {
		scroll1.each(function () {
			const _thisScroll = $(this)
			const inner = _thisScroll.find('.js-scroll1-inner')
			const content = _thisScroll.find('.js-scroll1-content')
			const icon = _thisScroll.find('.js-scroll1-icon')

			content.innerWidth() > inner.innerWidth() ? icon.addClass('is-active') : icon.removeClass('is-active')

			inner.on('scroll', function () {
				if ($(this).scrollLeft() > 1) {
					icon.addClass('is-hide')

				} else {
					icon.removeClass('is-hide')
				}
			})
		})
	}
})
