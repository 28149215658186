import $ from 'jquery';

$("#phone1, #phone2, #phone3").on("keypress", function(event) {
  // Allow only numeric input (0-9) and special keys like Backspace
  var keyCode = event.which;
  if (keyCode < 48 || keyCode > 57) {
      event.preventDefault();
  }
});

$("#phone1, #phone2, #phone3").on("input", function() {
  // Get the values of the three input fields
  var value1 = $("#phone1").val();
  var value2 = $("#phone2").val();
  var value3 = $("#phone3").val();

  // Combine the values and set the value of the target input field
  $("#phone").val(value1 +  '-' + value2 + '-' + value3);
});