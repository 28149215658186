import $ from 'jquery';

$(function () {
	const $window = $(window);
	const $gnavbar = $('#js-gnav-bar');
	const $gnav = $('#js-gnav');
	const $cHeader = $('.c-header');
	const animationClass = 'is-scrolling';
	let originalState = false;

	// Open/Close Gnav
	$gnavbar.on('click', function () {
		// Toggle gnav
		$gnav.toggleClass('is-open');
		$gnavbar.toggleClass('is-open');
		window.settings.bodyFixed($gnavbar.hasClass('is-open'));

		if ($gnav.hasClass('is-open')) {
			originalState = $cHeader.hasClass(animationClass);
			if (!originalState) {
				$cHeader.addClass(animationClass);
			}
		} else {
			if (!originalState) {
				$cHeader.removeClass(animationClass);
			}
			originalState = false;
		}
	});

	// Scroll event
	$window.on('scroll', function () {
		if (window.settings.isBodyFixed()) {
			return;
		}

		let value = $window.scrollTop();
		let space = $('.c-mv1').innerHeight()
		let space1 = $('.c-mv2').innerHeight()

		if ($('.page-top').length) {
			space = $('.c-intro').innerHeight()
		}

		if (value > space || value > space1) {
			if (!$gnav.hasClass('is-open')) {
				$cHeader.addClass(animationClass);
			}
		} else {
			if (!$gnav.hasClass('is-open')) {
				$cHeader.removeClass(animationClass);
			}
		}
	});
});

if($(window).outerWidth() < 768){
	$(".c-intro").height($(window).outerHeight());
	$(".c-mv").height($(window).outerHeight());
}

