import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

$(function () {
  let slider1;

  setTimeout(() => {
    $(".c-intro").addClass('hidden').removeClass('visible'); // Ẩn c-intro
    $(".c-mv").removeClass('hidden').addClass('visible'); // Hiện c-mv
  }, 2000);

  if($(".c-slider01").length){
    setTimeout(() => {
      if (!slider1) {
          slider1 = new Swiper('.c-slider01', {
            loop: true,
            effect: 'fade',
            speed: 3000,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false,
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          });
      } else {
        slider1.on('init', function() {
          this.autoplay.start();
        });
      }
      slider1.update();

      setTimeout(() => {
        $(".c-mv__headline").addClass("is-active");
      }, 0);
    }, 4000);
  }
});

var dragSize = $('#job_list_slider').data('drag-size') ? $this.data('drag-size') : 50;
let job_list_slider = new Swiper('#job_list_slider', {
  slidesPerView: 'auto',
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    320: {
      spaceBetween: 20,
    },
    768: {
      spaceBetween: 0,
    },
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
    dragSize: dragSize
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
});